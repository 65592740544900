<template>
	<div>
		<announcement-banner />
		<announcement-text />
	</div>
</template>

<script>
import AnnouncementBanner from "./Banner.vue";
import AnnouncementText from "./Text.vue";

export default {
	components: {
		AnnouncementBanner,
		AnnouncementText,
	},
};
</script>
