<template>
	<div class="pb-1">
		<b-card class="mb-1" no-body>
			<b-card-header class="pb-50">
				<div>
					<h3>Announcement Banner</h3>
					<p>Max 250px Width & 350px Height - You can upload jpg, png, jpeg, gif, svg, webp - Max 2MB</p>
				</div>
			</b-card-header>
			<b-card-body>
				<b-row class="mt-2">
					<b-col cols="12" md="6" class="mb-md-0 mb-2">
						<div class="mb-2">
							<b-form-input v-model="url" placeholder="URL" />
						</div>
						<div>
							<label for="images" class="drop-container">
								<span class="drop-title">Drop files here</span>
								or
								<input type="file" id="images" accept="image/*" @change="fileUploaded" ref="announcement" />
							</label>
						</div>
					</b-col>
					<b-col cols="12" md="6" class="mb-md-0 mb-2">
						<div class="d-flex flex-column align-items-center">
							<p class="text-center">Preview</p>
							<img width="500px" v-if="preview" :src="preview" />
						</div>
					</b-col>
				</b-row>
				<b-row class="mt-5">
					<b-col cols="12">
						<button class="btn btn-primary btn-block" @click="save">Save</button>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BFormInput, BInputGroup, BInputGroupPrepend, BRow, BCol, BButton, BBadge } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BButton,
		BBadge,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			announcement: null,
			url: null,
			preview: null,
		};
	},
	mounted() {
		this.$http.get("/settings/announcement/banner").then((res) => {
			if (res.data) {
				if (res.data.announcement) {
					this.url = res.data.announcement.url;
					if (res.data.announcement.image) {
						this.preview = res.data.url + "/" + res.data.announcement.image;
					}
				}
			}
		});
	},
	methods: {
		fileUploaded() {
			if (this.$refs.announcement.files.length > 0) {
				this.announcement = this.$refs.announcement.files[0];
				this.preview = URL.createObjectURL(this.$refs.announcement.files[0]);
			}
		},
		save() {
			const formData = new FormData();
			if (this.announcement) {
				formData.append("image", this.announcement);
			}
			formData.append("url", this.url);
			const headers = { "Content-Type": "multipart/form-data" };
			this.$http.post("/settings/announcement/banner", formData, { headers }).then((res) => {
				if (res.status == 200) {
					this.$bvToast.toast("Images saved successfully!", {
						title: `Success`,
						variant: "success",
						solid: true,
					});
				}
			});
		},
	},
};
</script>

<style scoped>
.drop-container {
	position: relative;
	display: flex;
	gap: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 200px;
	padding: 20px;
	border-radius: 10px;
	border: 2px dashed #555;
	color: #444;
	cursor: pointer;
	transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
	background: #d3d1eb;
	border-color: #111;
}

.drop-container:hover .drop-title {
	color: #222;
}

.drop-title {
	color: #444;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	transition: color 0.2s ease-in-out;
}

input[type="file"] {
	width: 350px;
	max-width: 100%;
	color: #444;
	padding: 5px;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #555;
}

input[type="file"]::file-selector-button {
	margin-right: 20px;
	border: none;
	background: #7d71f5;
	padding: 10px 20px;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
	background: #7d71f5;
}
</style>
