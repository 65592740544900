<template>
	<div class="pb-1">
		<b-card class="mb-1" no-body>
			<b-card-header class="pb-50">
				<div>
					<h3>Text Announcements</h3>
				</div>
			</b-card-header>
			<b-card-body>
				<b-row class="mt-2">
					<b-col cols="12" md="6" class="mb-md-0 mb-2">
						<h5>List of Announcements</h5>
						<div class="text-center text-muted mt-5" v-if="announcements.length == 0">Nothing here! Add something.</div>
						<div class="mt-2" v-for="a in announcements">
							<div class="px-2 py-1 rounded bg-light d-flex justify-content-between align-items-center">
								<p class="flex-grow-1 m-0">{{ a.body }}</p>
								<div class="d-flex justify-content-center align-items-center">
									<p class="m-0 mx-2 text-center" style="min-width: 100px">{{ $moment(a.created_at).format("ll") }}</p>
									<span class="text-danger cursor-pointer" @click="deleteAnnouncement(a.id)"><feather-icon icon="TrashIcon" size="18" /></span>
								</div>
							</div>
						</div>
					</b-col>
					<b-col cols="12" md="6" class="mb-md-0 mb-2">
						<h5>Add Announcement</h5>
						<form action="#" method="post" @submit.prevent="add">
							<div class="mt-1">
								<label>Text</label>
								<b-form-textarea v-model="announcement.text" placeholder="eg. Advensure revenue crosses one million dollars" required />
							</div>
							<div class="mt-1">
								<label>Button Text (Optional)</label>
								<b-form-input v-model="announcement.button" placeholder="eg. Read More" />
							</div>
							<div class="mt-1">
								<label>URL (Optional)</label>
								<b-form-input v-model="announcement.url" placeholder="eg. https://advensure.io" />
							</div>
							<button class="btn btn-primary btn-block mt-2" type="submit">Add</button>
						</form>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import { getUserData } from "@/auth/utils";
import { BCard, BCardHeader, BCardBody, BFormInput, BFormTextarea, BInputGroup, BInputGroupPrepend, BRow, BCol, BButton, BBadge } from "bootstrap-vue";
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		BFormTextarea,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,
		BButton,
		BBadge,
	},
	data() {
		return {
			announcements: [],
			announcement: {
				text: null,
				button: null,
				url: null,
				user_id: null,
			},
		};
	},
	mounted() {
		this.announcement.user_id = getUserData().id;
		this.$http.get("/settings/announcements").then((res) => {
			if (res.data) {
				this.announcements = res.data;
				this.$forceUpdate();
			}
		});
	},
	methods: {
		add() {
			this.$http.post("/settings/announcement/add", this.announcement).then((res) => {
				if (res.status == 200) {
					this.$bvToast.toast("Announcement added successfully!", {
						title: `Success`,
						variant: "success",
						solid: true,
					});
					if (res.data) {
						this.announcements = res.data;
						this.$forceUpdate();
					}
					this.announcement = {
						text: null,
						button: null,
						url: null,
						user_id: getUserData().id,
					};
				}
			});
		},
		deleteAnnouncement(id) {
			if (confirm("Are you sure?")) {
				this.$http.post(`/settings/announcement/${id}/delete`).then((res) => {
					if (res.status == 200) {
						this.$bvToast.toast("Announcement deleted successfully!", {
							title: `Success`,
							variant: "success",
							solid: true,
						});
						if (res.data) {
							this.announcements = res.data;
							this.$forceUpdate();
						}
					}
				});
			}
		},
	},
};
</script>
